import { createTheme } from "@mui/material";
import {
  MuiButton,
  MuiFormHelperText,
  MuiFormLabel,
  MuiTypography,
  MuiTextField,
  MuiFormControl,
  MuiAutocomplete,
  MuiLink,
  MuiDialog,
  MuiPagination,
} from "./themeComponent";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0169BC",
    },
    primaryMedium: {
      main: "#0169BC",
    },
    secondary: {
      main: "#009687",
    },
    tertiary: {
      main: "#0d3c61",
    },
    background: {
      main: "#f5f5f5",
    },
    app: {
      main: "#006abf",
    },
  },
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
  },
  components: {
    MuiButton: {
      ...MuiButton,
    },
    MuiFormLabel: {
      ...MuiFormLabel,
    },
    MuiFormHelperText: {
      ...MuiFormHelperText,
    },
    MuiTypography: {
      ...MuiTypography,
    },
    MuiTextField: {
      ...MuiTextField,
    },
    MuiFormControl: {
      ...MuiFormControl,
    },
    MuiAutocomplete: {
      ...MuiAutocomplete,
    },
    MuiLink: {
      ...MuiLink,
    },
    ...MuiDialog,
    ...MuiPagination,
  },
});

export default theme;
