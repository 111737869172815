import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { auth } from "./services/API/auth";
import { publicApi } from "./services/API/public";
import authSlice from "./slices/authSlice";
import notificationSlice from "./slices/notification";
import { serviceAreaApi } from "./services/API/serviceArea";
import { serviceTypesApi } from "./services/API/service-types";
import { userApi } from "./services/API/users";
import { suppressionsApi } from "./services/API/suppression";
import { notificationsApi } from "./services/API/notification";
import { unitsApi } from "./services/API/unit";
import { PropertyApi } from "./services/API/property";
import { workOrderApi } from "./services/API/jobs";
import { disputesApi } from "./services/API/dispute";
import { invoiceApi } from "./services/API/invoice";
import { communicationApi } from "./services/API/communication";
import { historyApi } from "./services/API/history";
import { tagsApi } from "./services/API/tags";
import { tasksApi } from "./services/API/tasks";
import { woHistoryApi } from "./services/API/wo_history";
import { projectsApi } from "./services/API/projects";
import { alertApi } from "./services/API/alert-setting";
import { adminApi } from "./services/API/admin";

// Root reducer function
const rootReducer = combineReducers({
  // Add RTK Query reducers
  [auth.reducerPath]: auth.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
  [serviceAreaApi.reducerPath]: serviceAreaApi.reducer,
  [serviceTypesApi.reducerPath]: serviceTypesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [suppressionsApi.reducerPath]: suppressionsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [unitsApi.reducerPath]: unitsApi.reducer,
  [PropertyApi.reducerPath]: PropertyApi.reducer,
  [workOrderApi.reducerPath]: workOrderApi.reducer,
  [disputesApi.reducerPath]: disputesApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
  [communicationApi.reducerPath]: communicationApi.reducer,
  [historyApi.reducerPath]: historyApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [woHistoryApi.reducerPath]: woHistoryApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [alertApi.reducerPath]: alertApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,

  // Slice reducers for stored data
  authSlice: authSlice,
  notificationSlice: notificationSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      auth.middleware,
      publicApi.middleware,
      serviceAreaApi.middleware,
      serviceTypesApi.middleware,
      userApi.middleware,
      suppressionsApi.middleware,
      notificationsApi.middleware,
      unitsApi.middleware,
      PropertyApi.middleware,
      workOrderApi.middleware,
      disputesApi.middleware,
      invoiceApi.middleware,
      communicationApi.middleware,
      historyApi.middleware,
      tagsApi.middleware,
      tasksApi.middleware,
      woHistoryApi.middleware,
      projectsApi.middleware,
      alertApi.middleware,
      adminApi.middleware
    );
  },
});

// Setup RTK Query listeners
// setupListeners(auth.dispatch);
export default store;
