import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const PropertyApi = createApi({
  reducerPath: "PropertyApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: ({ offset, limit, order_by, sort_by, role, flag }) =>
        `/properties?${offset ? `offset=${offset}` : ""}${
          limit ? `&limit=${limit}` : ""
        }${order_by ? `&order_by=${order_by}` : ""}${
          sort_by ? `&sort_by=${sort_by}` : ""
        }${
          role ? `&role=${role}` : ""
        }${flag ? `&flag=${flag}` : ""}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    createProperties: builder.mutation({
      query: ({ data }) => ({
        url: `/properties`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    updateProperties: builder.mutation({
      query: ({ id, data }) => ({
        url: `/properties/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useCreatePropertiesMutation,
  useUpdatePropertiesMutation,
} = PropertyApi;
