import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_BASE_URL }),
  endpoints: (builder) => ({
    // Use query for getting data
    getServiceTypes: builder.query({
      query: () => "/public/service-types",
    }),
    getServiceArea: builder.query({
      query: () => "/public/service-area",
    }),
    refreshStripeAccountLink: builder.mutation({
      query: ({ token }) => ({
        url: `/public/users/refresh-stripe-account-link?token=${token}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response,
    }),
  }),
});

export const { useGetServiceTypesQuery, useGetServiceAreaQuery ,useRefreshStripeAccountLinkMutation } = publicApi;
