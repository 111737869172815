import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../fetchBaseQuery";

export const auth = createApi({
  reducerPath: "auth",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    // "POST or PUT OR PATCH” changes the data, we use the mutation keyword instead of query
    login: builder.mutation({
      query: (user) => ({
        url: "/auth/login",
        method: "POST",
        body: user,
      }),
      // transformErrorResponse: (response, meta, arg) => response.status,
    }),
    register: builder.mutation({
      query: (user) => ({
        url: "/auth/register",
        method: "POST",
        body: user,
      }),
    }),
    profile: builder.query({
      query: ({ token }) => ({
        url: "/users/profile",
        method: "GET",
        ...(token
          ? {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          : {}),
      }),
      transformResponse: (response, meta, arg) => {
        let is_admin = ["Admin", "Super Admin"].includes(
          response?.data?.role?.name
        );
        let is_sp = ["Service Provider"].includes(response?.data?.role?.name);
        let is_pm = ["Property Manager"].includes(response?.data?.role?.name);
        let userType = response?.data?.role?.name;
        return {
          ...response.data,
          userType: userType,
          is_admin: is_admin,
          is_sp: is_sp,
          is_pm: is_pm,
        };
      },
      transformErrorResponse: (response) => {
        if (response?.data?.error?.message === "jwt expired") {
          return { ...response, is_logout: true };
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (user) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: user,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `/auth/reset-password/${localStorage.getItem(
          "reset-password-token"
        )}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useRegisterMutation,
  useProfileQuery,
  useResetPasswordMutation,
} = auth;
