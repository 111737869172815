import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const tagsApi = createApi({
  reducerPath: "tagsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getTagsList: builder.query({
      query: (tags) => `/tags/${tags}`,
      transformResponse: (response, meta, arg) => response,
    }),

    createTags: builder.mutation({
      query: ({ id, data }) => ({
        url: `/wo-tags/${id}`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
    deleteTags: builder.mutation({
      query: ({ id, data }) => ({
        url: `/wo-tags/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetTagsListQuery,
  useCreateTagsMutation,
  useDeleteTagsMutation,
} = tagsApi;
