import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../fetchBaseQuery";

export const suppressionsApi = createApi({
  reducerPath: "suppressionsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    supressionList: builder.query({
      query: ({ offset, limit, order_by, sort_by }) =>
        `/suppressions?offset=${offset}&limit=${limit}&order_by=${order_by}&sort_by=${sort_by}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    createSupression: builder.mutation({
      query: ({ data }) => ({
        url: `/suppressions`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    deleteSupressionEmail: builder.mutation({
      query: ({ id }) => ({
        url: `/suppressions/${id}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useSupressionListQuery,
  useCreateSupressionMutation,
  useDeleteSupressionEmailMutation,
} = suppressionsApi;
