import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./Theme/theme";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AlertProvider } from "./hooks/useAlert";
import Loading from "./components/common/loading";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <BrowserRouter>
              <AlertProvider>
                <App />
              </AlertProvider>
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
