import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const historyApi = createApi({
  reducerPath: "historyApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getSmsHistory: builder.query({
      query: ({ offset, limit, order_by, sort_by, role }) =>
        `/sms-history?${offset ? `offset=${offset}` : ""}${
          limit ? `&limit=${limit}` : ""
        }${order_by ? `&order_by=${order_by}` : ""}${
          sort_by ? `&sort_by=${sort_by}` : ""
        }${role ? `&role=${role}` : ""}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    getEmailHistory: builder.query({
      query: ({ offset, limit, order_by, sort_by, role }) =>
        `/email-history?${offset ? `offset=${offset}` : ""}${
          limit ? `&limit=${limit}` : ""
        }${order_by ? `&order_by=${order_by}` : ""}${
          sort_by ? `&sort_by=${sort_by}` : ""
        }${role ? `&role=${role}` : ""}`,
      transformResponse: (response, meta, arg) => response.data,
    })
  }),
});

export const {
  useGetEmailHistoryQuery,
  useGetSmsHistoryQuery
} = historyApi;
