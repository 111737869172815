import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getStatistics: builder.query({
      query: () => `/admin/get-statistics`,
      transformResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const { useGetStatisticsQuery } = adminApi;
