import io from "socket.io-client";
const socket = io(process.env.REACT_APP_API_BASE_URL);

const registerSocketListeners = (eventHandlers) => {
  Object.keys(eventHandlers).forEach((event) => {
    socket.on(event, (data) => {
      return eventHandlers[event](data);
    });
  });
};

export { socket, registerSocketListeners };
