import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const unitsApi = createApi({
  reducerPath: "unitsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUnits: builder.query({
      query: ({ offset, limit, order_by, sort_by, role }) =>
        `/units?${offset !== undefined ? `offset=${offset}` : ""}${
          limit ? `&limit=${limit}` : ""
        }${order_by ? `&order_by=${order_by}` : ""}${
          sort_by ? `&sort_by=${sort_by}` : ""
        }${role ? `&role=${role}` : ""}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    createUnits: builder.mutation({
      query: ({ data }) => ({
        url: `/units`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    updateUnits: builder.mutation({
      query: ({ id, data }) => ({
        url: `/units/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetUnitsQuery,
  useCreateUnitsMutation,
  useUpdateUnitsMutation,
} = unitsApi;
