import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../fetchBaseQuery";

export const serviceAreaApi = createApi({
  reducerPath: "serviceAreaApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    serviceAreas: builder.query({
      query: ({ offset, limit, order_by, sort_by }) =>
        `/service-area?offset=${offset}&limit=${limit}&order_by=${order_by}&sort_by=${sort_by}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    createServiceArea: builder.mutation({
      query: (service_area_name) => ({
        url: "/service-area",
        method: "POST",
        body: { service_area_name: service_area_name },
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
    updateServiceArea: builder.mutation({
      query: ({ id: id, service_area_name: service_area_name }) => ({
        url: `/service-area/${id}`,
        method: "PUT",
        body: { service_area_name: service_area_name },
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
    delete: builder.mutation({
      query: (id) => ({
        url: `/service-area/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useServiceAreasQuery,
  useCreateServiceAreaMutation,
  useUpdateServiceAreaMutation,
  useDeleteMutation,
} = serviceAreaApi;
