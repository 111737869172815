import { createApi } from "@reduxjs/toolkit/query/react";
import _ from "lodash";
import { baseQuery } from "../../fetchBaseQuery";
import { USER_TYPES, isSame } from "../../../constants";
import { isAllEstimateApprovedInFirstTime } from "../../../utils";

export const workOrderApi = createApi({
  reducerPath: "workOrderApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getWorkOrder: builder.query({
      query: ({ id }) => `/work-order/${id}`,
      transformResponse: (response, meta, arg) => {
        const workorder = response.data.workorder;
        // const custom_descriptions = _.flatten(
        //   _.map(response?.data?.workorder?.descriptions, "estimations")
        // );

        let estimation = _.flatten(
          _.map(response?.data?.workorder?.descriptions, "estimations")
        );

        estimation = _.map(estimation, (item) => {
          return {
            ...item,
            total: isSame(
              workorder.estimation_source, // sp  , sp , admin , admin ,
              response?.data.user.role.name // admin ,  sp , admin, , sp
            )
              ? Number(item.amount)
              : Number(item.amount) + Number(item.markup_amount),
          };
        });
        const estimation_cost = _.reduce(
          estimation,
          (acc, cur) => acc + cur.total,
          0
        );

        let approved_cost = Boolean(workorder.approval_amount)
          ? !isSame(
              workorder.estimation_source, // sp  , sp , admin , admin ,
              response?.data.user.role.name // admin ,  sp , admin, , sp
            )
            ? Number(workorder.approval_amount)
            : Number(workorder.approval_amount) +
              Number(workorder.approval_amount_markup)
          : _.reduce(
              _.filter(estimation, ["status", "approved"]),
              (acc, cur) => acc + cur.total,
              0
            );

        if (
          workorder.estimation_source === USER_TYPES.ADMIN &&
          Boolean(workorder.approval_amount)
        ) {
          approved_cost = isSame(
            workorder.estimation_source,
            response?.data.user.role.name
          )
            ? Number(workorder.approval_amount)
            : Number(workorder.approval_amount) +
              Number(workorder.approval_amount_markup);
        }

        const is_all_description_approved =
          isAllEstimateApprovedInFirstTime(estimation);

        if (Boolean(workorder?.cost_discount) && is_all_description_approved) {
          approved_cost =
            approved_cost -
            (approved_cost * Number(workorder.cost_discount)) / 100;
        }

        return {
          ...response.data.workorder,
          estimation,
          estimation_cost,
          approved_cost,
        };
      },
    }),

    createWorkOrder: builder.mutation({
      query: ({ data }) => ({
        url: `/work-order`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    updateWorkOrder: builder.mutation({
      query: ({ id, data, token }) => ({
        url: `/work-order/${id}`,
        method: "PUT",
        ...(token
          ? {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          : {}),
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    getStatusWiseWorkOrder: builder.query({
      query: ({
        type,
        status,
        limit,
        sort_by,
        filteres,
        projectId,
        offset,
      }) => ({
        url: `/work-order?${type ? `type=${type}` : ""}${
          projectId ? `&projectId=${projectId}` : ""
        }${status ? `&status=${status}` : ""}${limit ? `&limit=${limit}` : ""}${
          offset ? `&offset=${offset}` : ""
        }${sort_by ? `&sort_by=${sort_by}` : ""}${filteres ? filteres : ""}`,
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),

    verifyToken: builder.mutation({
      query: ({ id, data, token }) => ({
        url: `/work-order/verify-link/${id}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    workOrderRatingReviews: builder.mutation({
      query: ({ id, data, token }) => ({
        url: `/work-order/rating/${id}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
    cloneWorkOrder: builder.mutation({
      query: ({ id }) => ({
        url: `/work-order/clone/${id}`,
        method: "POST",
        // body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetWorkOrderQuery,
  useCreateWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useGetStatusWiseWorkOrderQuery,
  useVerifyTokenMutation,
  useWorkOrderRatingReviewsMutation,
  useCloneWorkOrderMutation,
} = workOrderApi;
