import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../fetchBaseQuery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    userList: builder.query({
      query: ({ offset, limit, order_by, sort_by, role, flag, filter, type }) =>
        `/users?${offset ? `offset=${offset}` : ""}${
          limit ? `&limit=${limit}` : ""
        }${order_by ? `&order_by=${order_by}` : ""}${
          sort_by ? `&sort_by=${sort_by}` : ""
        }${role ? `&role=${role}` : ""}${flag ? `&flag=${flag}` : ""}${
          type ? `&type=${type}` : ""
        }${filter ? filter : ""}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    getUserProfile: builder.query({
      query: ({ id }) => `/users/profile/${id}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    getUserRole: builder.query({
      query: () => `/roles`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    getSegmentationUserEmail: builder.query({
      query: ({ role_id }) => `/users/segmentation/${role_id}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    getVendorsListForWorkOrder: builder.query({
      // Work order ID
      query: (id) => ({
        url: `/users/get-service-providers/${id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),

    updateUserProfile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/profile${id ? `/${id}` : ""}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    createTenantAndOwner: builder.mutation({
      query: ({ data }) => ({
        url: `/users/create-tenant-owner`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    createUserProfile: builder.mutation({
      query: ({ data }) => ({
        url: `/users/create`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    adminAnotherLogin: builder.mutation({
      query: ({ user_id }) => `/users/login-another-account/${user_id}`,
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    verifyServiceProvider: builder.mutation({
      query: ({ user_id }) => `/users/verify/${user_id}`,
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    adminResetAnotherPassword: builder.mutation({
      query: ({ user_id, data }) => ({
        url: `/users/reset-another-user-password/${user_id}`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
    changePassword: builder.mutation({
      query: ({ data }) => ({
        url: `/users/change-password`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
    sendReminderEmailToLinkBankAccount: builder.mutation({
      query: ({ id }) => ({
        url: `/users/send-reminder-email-to-link-bank-account/${id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response,
    }),
  }),
});

export const {
  useUserListQuery,
  useGetUserProfileQuery,
  useGetVendorsListForWorkOrderQuery,
  useUpdateUserProfileMutation,
  useCreateUserProfileMutation,
  useCreateTenantAndOwnerMutation,
  useChangePasswordMutation,
  useGetSegmentationUserEmailQuery,
  useAdminAnotherLoginMutation,
  useVerifyServiceProviderMutation,
  useAdminResetAnotherPasswordMutation,
  useSendReminderEmailToLinkBankAccountMutation,
  useGetUserRoleQuery,
} = userApi;
