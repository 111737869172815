import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const alertApi = createApi({
  reducerPath: "alertApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAlertList: builder.query({
      query: () => `/alerts-setting`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    updateAlertList: builder.mutation({
      query: ({ id, data }) => ({
        url: `/alerts-setting/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const { useGetAlertListQuery, useUpdateAlertListMutation } = alertApi;
