import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";
import _ from "lodash";

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getProjectList: builder.query({
      query: () => `/projects`,
      transformResponse: (response, meta, arg) => {

        const filteredData = _.map(response?.data?.rows || [], (data) => {
          const workorders = _.filter(
            data?.workorders,
            (workorder) => !["Stopped"].includes(workorder?.status)
          );
          return { ...data, workorders: workorders };
        });
        return { count: response?.data?.count, rows: filteredData };
      },
    }),
    getProjectDetails: builder.query({
      query: (id) => `/projects/detail/${id}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    createProjects: builder.mutation({
      query: ({ data }) => ({
        url: `/projects`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    updateProjectPriority: builder.mutation({
      query: ({ id, data }) => ({
        url: `/projects/update-priority/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    updateProjects: builder.mutation({
      query: ({ id, data }) => ({
        url: `/projects/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetProjectListQuery,
  useGetProjectDetailsQuery,
  useCreateProjectsMutation,
  useUpdateProjectPriorityMutation,
  useUpdateProjectsMutation,
} = projectsApi;
