import { createSlice } from "@reduxjs/toolkit";
import { notificationsApi } from "../services/API/notification";

const initialState = {
  unreadNotifications: 0,
};

const notificationSlice = createSlice({
  name: "notificationSlica",
  initialState: initialState,
  reducers: {
    setUnreadNotifications: (state, action) => {
      state.unreadNotifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationsApi.endpoints.unreadNotifications.matchFulfilled,
      (state, action) => {
        state.unreadNotifications = action.payload?.unread_notification_count;
      }
    );
  },
});

export default notificationSlice.reducer;

export const getUnreadNotification = (state) =>
  state.notificationSlice.unreadNotifications;

export const { setUnreadNotifications } = notificationSlice.actions;
