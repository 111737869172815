import { createSlice } from "@reduxjs/toolkit";
import { auth } from "../services/API/auth";

const initialState = {
  user: {},
};

const authSlice = createSlice({
  name: "authSlice",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      auth.endpoints.profile.matchFulfilled,
      (state, action) => {
        state.user = action.payload;
      }
    );
  },
});

export default authSlice.reducer;

export const getLoginUser = (state) => state.authSlice.user;

export const { setUser } = authSlice.actions;
