import { createApi } from "@reduxjs/toolkit/query/react";
import _ from "lodash";
import { baseQuery } from "../../fetchBaseQuery";
import { isSame } from "../../../constants";

export const invoiceApi = createApi({
  reducerPath: "invoiceApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    invoiceId: builder.query({
      query: () => `/invoice/generate-id`,
      transformResponse: (response) => response.data,
    }),

    getInvoice: builder.query({
      query: ({ id }) => `/invoice/${id}`,
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    getInvoiceList: builder.query({
      query: ({ type, offset, status, limit, filteres }) =>
        `/invoice?${type ? `type=${type}` : ""}${
          status ? `&status=${status}` : ""
        }${limit ? `&limit=${limit}` : ""}${
          offset !== undefined ? `&offset=${offset}` : ""
        }${filteres ? filteres : ""}`,
      transformResponse: (response) => response.data,
    }),

    createInvoice: builder.mutation({
      query: ({ data }) => ({
        url: `/invoice`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    updateInvoice: builder.mutation({
      query: ({ id, data }) => ({
        url: `/invoice/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    deleteInvoice: builder.mutation({
      query: ({ id }) => ({
        url: `/invoice/${id}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    paymentToServiceProvider: builder.mutation({
      query: ({ id }) => ({
        url: `/invoice/pay-to-service-provider/${id}`,
        method: "PUT",
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    markAsPaidToServiceProvider: builder.mutation({
      query: ({ id, data }) => ({
        url: `/invoice/mark-as-paid/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    moveToRecievable: builder.mutation({
      query: ({ id, data }) => ({
        url: `/invoice/move-to-receivable/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    markAsRecievedPayment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/invoice/mark-as-received/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useInvoiceIdQuery,
  useGetInvoiceQuery,
  useGetInvoiceListQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  usePaymentToServiceProviderMutation,
  useMarkAsPaidToServiceProviderMutation,
  useMoveToRecievableMutation,
  useMarkAsRecievedPaymentMutation,
} = invoiceApi;
