import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading() {
  return (
    <div className="loader">
      <CircularProgress disableShrink />
    </div>
  );
}
