import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";

export const PUBLIC_ROUTES = [
  "/",
  "/register",
  "/signup",
  "/forgot-password",
  "/terms",
  "/reset-password",
  "/workorder-suggest-schedule",
  "/workorder-ratings-review",
  "/workorder-reschedule-feedback",
  "/workorder-time-lapsed-feedback",
  "/stripe-refresh-account-link",
  "/workorder-communications",
];

export const STATUSES = [
  { value: "Coordinating schedule", name: "Coordinating schedule" },
  { value: "Paused / On Hold", name: "Paused / On Hold" },
  { value: "Scheduled", name: "Scheduled" },
  { value: "In Progress", name: "In Progress" },
  { value: "Stopped", name: "Stopped" },
  { value: "Completed", name: "Completed" },
  { value: "Invoiced", name: "Invoiced" },
];

export const JOB_STATUS = {
  COORDINATING_SCHEDULE: "Coordinating schedule",
  WAITING_TO_BE_CLAIMED: "Waiting to be claimed",
  COORDINATING: "Coordinating",
  SCHEDULED: "Scheduled",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  INVOICED: "Invoiced",
  STOPPED: "Stopped",
  UNDER_REVIEW: "Under Review",
};

export const USER_TYPES = {
  ADMIN: "Admin",
  OWNER: "Owner",
  PROPERTY_MANAGER: "Property Manager",
  SERVICE_PROVIDER: "Service Provider",
  TENANT: "Tenant",
  SUPER_ADMIN: "Super Admin",
};

export const roleWiseMenu = {};

export const roleWisePageAccess = {
  [USER_TYPES.ADMIN]: [],
  [USER_TYPES.SUPER_ADMIN]: [],
  [USER_TYPES.OWNER]: [],
  [USER_TYPES.SERVICE_PROVIDER]: [
    "/jobs",
    "/jobs/job-overview",
    "/jobs/work-order",
    "/jobs/calendar",
    "/settings",
    "/notifications",
    "/create-invoice",
  ],
  [USER_TYPES.PROPERTY_MANAGER]: [
    "/dashboard",
    "/jobs",
    "/jobs/create",
    "/jobs/job-overview",
    "/jobs/work-order",
    "/jobs/calendar",
    "/settings",
    "/notifications",
    "/filters",
    "/projects",
    "/properties",
    "/disputes/open",
    "/disputes/closed",
  ],
};

export const regex = {
  email: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  usZipCode: /^[0-9]{5}(?:-[0-9]{4})?$/,
};

export const IMAGES = {
  DEFAULT_USER_AVATAR:
    "https://files.candumaintenance.com/avatars/f5664958b9b3f3ee521f3afeb880b3a4.png",
};

export const userStatus = [
  { value: "Verified", name: "Verified" },
  { value: "Unverified", name: "Unverified" },
  { value: "Probationed", name: "Probationed" },
  { value: "Blacklisted", name: "Blacklisted" },
];
export const timeZone = [
  {
    value: "480",
    name: "Pacific Time Zone",
  },
  {
    value: "600",
    name: "Hawaii Standard Time",
  },
  {
    value: "540",
    name: "Alaska Standard Time",
  },
  {
    value: "360",
    name: "Central Time Zone",
  },
  {
    value: "300",
    name: "Eastern Time Zone",
  },
  {
    value: "420",
    name: "Mountain Time Zone",
  },
];
export const ROLES = [
  "Super Admin",
  "Admin",
  "Property Manager",
  "Service Provider",
  "Tenant",
  "Owner",
];

export const USERTYPES = [
  { value: "Admin", name: "Admin" },
  {
    value: "Service Provider",
    name: "Service Provider",
  },
  {
    value: "Property Manager",
    name: "Property Manager",
  },
  { value: "Tenant", name: "Tenant" },
  { value: "Owner", name: "Owner" },
];

export const timeAgo = (string) => {
  let date = new Date(string);

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }

  return Math.floor(seconds) + " seconds";
};

export const truncateFilename = (url) => {
  let url_split = url.split("/");

  return url_split[url_split.length - 1];
};

export const WORK_ORDER_ESTIMATION_SOURCE = {
  ADMIN: "Admin",
  SERVICE_PROVIDER: "Service Provider",
};

export const isSame = (estimation_source, user_role, flag = "amount") => {
  let checkUserRoles = [];
  if (estimation_source === ROLES[3]) {
    checkUserRoles = ROLES[3];
  } else {
    checkUserRoles = [ROLES[1], ROLES[0]];
    if (flag === "amount") {
      checkUserRoles.push(ROLES[2]);
    }
  }
  if (
    [ROLES[3], ROLES[1]].includes(estimation_source) &&
    checkUserRoles.includes(user_role)
  ) {
    return true;
  }
  return false;
};

export const ESTIMATION_BADGE_COLOR = {
  BG: {
    pending: "#fbc02d",
    declined: "#d32f2f",
    approved: "#689f38",
  },
  FG: {
    pending: "#000",
    approved: "#fff",
    declined: "#fff",
  },
};
export const AMOUNT_STATUS_LIST = {
  PENDING: "pending",
  APPROVED: "approved",
  DECLINED: "declined",
};
export const JOB_ESTIMATION_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  DECLINED: "declined",
};

export const COST_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  DECLINED: "declined",
};

export const INVOICE_STATUS = {
  PENDING: "pending",
  ACCOUNT_PAYABLE: "accounts_payable",
  PAID: "paid",
  ACCOUNT_RECEIVABLE: "accounts_receivable",
  RECEIVED_PAYMENT: "received_payments",
};
export const INVOICE_STATUS_SHOW_DATA = {
  pending: "pending",
  accounts_payable: "accounts payable",
  paid: "paid",
  accounts_receivable: "accounts receivable",
  received_payments: "received payments",
};

export const display_cost = (status, workorder) => {
  if (status === AMOUNT_STATUS_LIST.APPROVED) {
    return workorder.approved_cost;
  }
  if (workorder.approval_amount && status === AMOUNT_STATUS_LIST.APPROVED) {
    return workorder.approval_amount;
  }
  return workorder.estimation_cost;
};

export const WORK_ORDER_DISPLAY_DETAILS = {
  "Under Review": {
    BG: "#d1690033",
    FG: "#d06900",
    ICON: <CircleOutlinedIcon />,
  },
  "Coordinating schedule": {
    BG: "#c71a5333",
    FG: "#c61a54",
    ICON: <ScheduleOutlinedIcon />,
  },
  "Waiting to be claimed": {
    BG: "#85219733",
    FG: "#852196",
    ICON: <HourglassTopOutlinedIcon />,
  },
  Coordinating: {
    BG: "#da362b33",
    FG: "#d9362b",
    ICON: <QuestionAnswerOutlinedIcon />,
  },
  Scheduled: {
    BG: "#c58f0733",
    FG: "#c38e07",
    ICON: <CalendarTodayOutlinedIcon />,
  },
  "In Progress": {
    BG: "#009eb333",
    FG: "#00a0b4",
    ICON: <BoltOutlinedIcon />,
  },
  Stopped: {
    BG: "#da362b33",
    FG: "#d9362b",
    ICON: <DoDisturbOutlinedIcon />,
  },
  Completed: {
    BG: "#41954433",
    FG: "#419544",
    ICON: <TaskAltOutlinedIcon />,
  },
  Invoiced: {
    BG: "#36459b33",
    FG: "#36459a",
    ICON: <CreditCardOutlinedIcon />,
  },
};

export const WORK_ORDER_RESPONSE_MESSAGE = {
  DISPACH_TO_SERVICE_PROVIDER: "Service provider dispatched successfully",
  SUGGEST_A_SCHEDULE: "Work order scheduled successfully",
  CLAIM_WORK_ORDER: "Work order claim successfully",
  UPDATE_STATUS: "Work order status update successfully",
  SUBMIT_ESTIMATE: "Estimate submit successfully",
  EDIT_DETAILS: "Work order update Successfully",
  REJECT_WORK_ORDER: "Work order rejected successfully",
  CLONE_WORK_ORDER: "Clone work order successfully",
  CHOOSE_SCHEDULE: "Choose schedule successfully",
  ESTIMATE_SEND_MESSAGE: "Send estimate message successfully",
  FILE_DISPUTES: "Work order disputes successfully",
  RATE_WORK_ORDER: "Workorder rated successfully",
  WORK_ORDER_ESTIMATE_APPROVED: "Estimate approved successfully",
  WORK_ORDER_ESTIMATE_DECLINED: "Estimate declined successfully",
  WORK_ORDER_ESTIMATE_REQUEST_FOR_REVISION:
    "Request for revision send successfully",
  REQUEST_FOR_RESCHEDULE: "Reschedule request send successfully",
  UNDER_REVIEW_APPROVED: "Under review work order approved successfully",
  COST_SUBMITTED: {
    [USER_TYPES.PROPERTY_MANAGER]:
      "Cost submitted to property manager successfully",
    [USER_TYPES.SERVICE_PROVIDER]:
      "Cost submitted to service provider successfully",
  },
};

export const TASK_TYPE = ["sms", "follow_up", "email"];
export const TASK_TYPE_OBJECT = [
  {
    name: "SMS",
    value: "sms",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Follow Up",
    value: "follow_up",
  },
];
export const TASK_TAB_LIST = [
  { label: "Today", value: "today", color: "black" },
  { label: "Overdue", value: "overdue", color: "red" },
  { label: "Future", value: "future", color: "green" },
];

export const getEmailDataForAlert = (shortname) => {
  // SEND_EMAIL_FOR_LINK_USER_BANK_ACCOUNT: "eml_selu_ba",
  // NEW_VENDOR_REGISTRATION_ADMIN_ALERT: "eml_nvr_aa",
  // FORGOT_PASSWORD: "eml_fp",
  // WELCOME_MESSAGE: "eml_wm",
  // SUGGEST_SCHEDULE: "eml_ss",
  // ESTIMATION_REQUEST_ADMIN: "eml_er_a",
  // SCHEDULE_SUGGESTED: "eml_ss",
  // SCHEDULE_APPROVED: "eml_sa",
  // CLAIMABLE_WORKORDER: "eml_cw",
  // DISPATCH_SERVICE_PROVIDERS_ALERT: "eml_dspa",
  // WORKORDER_STOPPED: "eml_ws",
  // ESTIMATION_SUBMITTED: "eml_es",
  // ESTIMATION_APPROVED: "eml_ea",
  // ESTIMATION_DECLINED: "eml_ed",
  // REQUEST_FOR_REVISION_RAISED: "eml_rfr_r",
  // WORKORDER_REJECTED: "eml_wr",
  // WORKORDER_COMPLETED: "eml_wc",
  // INVOICE_SUBMITTED: "eml_is",
  // INVOICE_UPDATED: "eml_iu",
  // PAYMENT_TRANSFERED: "eml_pt",
  // PAYMENT_RECEIVED: "eml_pr",
  // INVOICE_DISPUTED_TO_INVOICED: "eml_id_ti",
  // ADMIN_RESET_ANOTHER_USER_PASSWORD: "eml_ar_aup",
  // NEW_MESSAGE_IN_WORKORDER: "eml_nmw",
  // REQUESTED_FOR_RESCHEDULE: "eml_rr",
  // RATINGS_REVIEW_REQUESTED: "eml_rrr",
  // RATINGS_REVIEW_RECEIVED: "eml_rr_r",
  // RATINGS_REVIEW_SUBMITTED: "eml_rr_s",
  // RESCHEDULE_FEEDBACK_REQUESTED: "eml_rfr",
  // TIME_LAPSED_REASON_REQUESTED: "eml_tlrr",
  // NEW_UNDER_REVIEW_WORKORDER: "eml_nurw",
  // TASK_CREATED: "eml_tc",
  // TASK_UPDATED: "eml_tu",
  // TASK_COMPLETED: "eml_tc",
  // TASK_DELETED: "eml_td",
  // APPROVAL_AMOUNT_APPROVED: "eml_aaa",
  // ADMIN_ALERT_NON_COMMUNICATION_FROM_TENANT: "eml_aa_ncft",
  // WORKORDER_NOT_CLAIMED: "eml_wnc",
  // WORKORDER_STUCK_IN_PROGRESS: "eml_wsip",
  // WORKORDER_PENDING_TO_DISPATCH: "eml_wpd",
  // REQUEST_FOR_APPROVE_SCHEDULE_FROM_THE_SUGGESTED_SCHEDULES: "eml_rfas_s",
  // DAILY_REMINDER_TO_SERVICE_PROVIDER_OF_UNCLAIMED_THE_WORK_ORDERS: "eml_drsp_uwo",
  // ADMIN_NOTIFY_OF_UNCLAIMED_WORKORDERS_BY_THE_SERVICE_PROVIDER: "eml_anuw_sp",
  // REMINDER_FOR_RESCHEDULE_THE_WORKORDER: "eml_rfsw",
  // WORKORDER_MOVED_FROM_SCHEDULED_TO_IN_PROGRESS: "eml_wmst_ip",
  // APPOINTMENT_REMINDER_24_HOURS_BEFORE: "eml_ar_24hb",
  // DAILY_REMINDER_OF_IN_PROGRESS_WORKORDERS: "eml_dripw",
  // OLD_WORKORDER_RESTARTED: "eml_owr",
  // RESCHEDULE_FEEDBACK_RECEIVED: "eml_rfr",
  // WORKORDER_RETURN_TRIP: "eml_wrt",
  // RESCHEDULE_WORKORDER_BASED_ON_SERVICE_PROVIDER_ESTIMATION: "eml_rwbospe",

  const FormsData = {
    eml_selu_ba: {
      // SEND_EMAIL_FOR_LINK_USER_BANK_ACCOUNT
      title: "Link Bank Account Email",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },

    eml_owr: {
      // OLD_WORKORDER_RESTARTED
      title: "Work Order Restarted",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    eml_wrt: {
      // WORKORDER_RETURN_TRIP
      title: "Work Order Return Trip Info Message",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    eml_rwbospe: {
      // RESCHEDULE_WORKORDER_BASED_ON_SERVICE_PROVIDER_ESTIMATION
      title: "Reschedule work order based on service provider estimation",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_aa_ncft: {
      // ADMIN_ALERT_NON_COMMUNICATION_FROM_TENANT
      title: "Non Communication From Tenant",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_aaa: {
      // APPROVAL_AMOUNT_APPROVED
      title: "Approval Amount Approved",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },
    eml_anuw_sp: {
      // ADMIN_NOTIFY_OF_UNCLAIMED_WORKORDERS_BY_THE_SERVICE_PROVIDER
      title: "Admin Notify Unclaimed Workorders By The Service Provider",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_ar_24hb: {
      // APPOINTMENT_REMINDER_24_HOURS_BEFORE
      title: "Appointment Reminder (Before 24h)",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    // eml_ar_aup: {
    //   title: "Admin Reset Another User Password", ////////ADMIN_RESET_ANOTHER_USER_PASSWORD
    //   "Super Admin": false,
    //   Admin: false,
    //   "Property Manager": false,
    //   "Service Provider": true,
    // },
    eml_cw: {
      title: "Claim Work Order", //  "CLAIMABLE_WORKORDER",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    eml_aa_d: {
      // APPROVAL_AMOUNT_DECLINED
      title: "Approval Amount Rejected",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },

    eml_dripw: {
      title: "Work Order In-Progress Reminders",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    eml_drsp_uwo: {
      title: "Unclaimed Work Orders",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    eml_dspa: {
      title: "Dispatch Service Provider", // DISPATCH_SERVICE_PROVIDERS_ALERT,
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_ea: {
      //"ESTIMATION_APPROVED",
      title: "Estimate Approved By Property Manager",
      "title_for_Property Manager": "Estimate Approved By Admin",
      "title_for_Service Provider":
        "Estimate Approved By Property Manager/Admin",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    eml_ed: {
      //" "ESTIMATION_DECLINED"",
      title: "Estimate Declined By Property Manager",
      "title_for_Property Manager": "Estimate Dellined By Admin",
      "title_for_Service Provider":
        "Estimate Dellined By Property Manager/Admin",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    eml_er_a: {
      // "ESTIMATION_REQUEST_ADMIN",
      title: "Estimation Request Admin",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_es: {
      // "ESTIMATION_SUBMITTED",
      title: "Admin Estimation",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },
    eml_fp: {
      // "FORGOT_PASSWORD",
      title: "Forgot Password",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    eml_id_ti: {
      // "INVOICE_DISPUTED_TO_INVOICED",
      title: "Dispute To Invoice",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },

    eml_is: {
      // "INVOICE_SUBMITTED",
      title: "Invoice Raised By Service Provider",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_iu: {
      // "INVOICE_UPDATED",
      title: "Invoice Updated By Service Provider",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_nmw: {
      // "NEW_MESSAGE_IN_WORKORDER",
      title: "New Message in Work Order ",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    eml_nurw: {
      // ""NEW_UNDER_REVIEW_WORKORDER",",
      title: "Work Order In Under Review ",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_nvr_aa: {
      // NEW_VENDOR_REGISTRATION_ADMIN_ALERT
      title: "New Vendor Registration",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_pr: {
      // "PAYMENT_RECEIVED",
      title: "Received Payment From Property Manager",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_pt: {
      // PAYMENT_TRANSFERED
      title: "Payment Transfered To Service Provider",
      "title_for_Service Provider": "Payment Transfered",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    // eml_rfas_s: {
    //   // "REQUEST_FOR_APPROVE_SCHEDULE_FROM_THE_SUGGESTED_SCHEDULES",
    //   title: "Request For Reschedule",
    //   "Super Admin": false,
    //   Admin: false,
    //   "Property Manager": false,
    //   "Service Provider": true,
    // },
    eml_rfr:
      // "RESCHEDULE_FEEDBACK_REQUESTED",
      {
        title: "Reschedule feedback",
        "Super Admin": false,
        Admin: false,
        "Property Manager": false,
        "Service Provider": false,
      },
    eml_rfr_r:
      //  "REQUEST_FOR_REVISION_RAISED",
      {
        title: "Request for Revision",
        "Super Admin": true,
        Admin: true,
        "Property Manager": false,
        "Service Provider": true,
      },
    eml_rfsw: {
      //  "REMINDER_FOR_RESCHEDULE_THE_WORKORDER",
      title: "Reschedule Work Order Reminder",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_rr: {
      // "REQUESTED_FOR_RESCHEDULE",
      title: "Request Reschedule Work Order ",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_rr_r: {
      // "RATINGS_REVIEW_RECEIVED",
      title: "Rating Review Received",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": true,
    },
    eml_rr_s: {
      // "RATINGS_REVIEW_SUBMITTED",
      title: "Rating Submitted",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_rrr: {
      // "RATINGS_REVIEW_REQUESTED",
      title: "Rating Review Request",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_sa: {
      // "SCHEDULE_APPROVED",
      title: "Work Order Schedule Approved",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },
    eml_ss: {
      // "SCHEDULE_SUGGESTED",
      title: "Work Order Updated With Schedules",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },
    eml_tc: {
      // "TASK_COMPLETED",
      title: "Task Completed",
      "Super Admin": true,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_td: {
      // "TASK_DELETED",
      title: "Task Deleted",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_tlrr: {
      // "TIME_LAPSED_REASON_REQUESTED",
      title: "Time Lapsed Reason",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_tu: {
      // "TASK_UPDATED",
      title: "Task Updated",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_wc: {
      // "WORKORDER_COMPLETED",
      title: "Completed By Service Provider",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },
    eml_wm: {
      // "WELCOME_MESSAGE",
      title: "Welcome SMS for Work Order",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_wmst_ip: {
      // "WORKORDER_MOVED_FROM_SCHEDULED_TO_IN_PROGRESS",
      title: "Work Order Moved To In Progress Status",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    eml_wnc: {
      // "WORKORDER_NOT_CLAIMED",
      title: "Unclaimed Work Orders",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_wpd: {
      // "WORKORDER_PENDING_TO_DISPATCH",
      title: "Work Order Is Pending To Dispatch",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    eml_wr: {
      // " "WORKORDER_REJECTED",",
      title: "Work Order Is Rejected",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    eml_ws: {
      // "WORKORDER_STOPPED",
      title:
        "Work Order Stopped Due To Property Manager/Tenant/Service Provider Unresponsive",
      "title_for_Property Manager":
        "Work Order Stopped Due To Service Provider Unresponsive",
      "title_for_Service Provider":
        "Work Order Stopped Due To Property Manager Unresponsive",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    eml_wsip: {
      // "WORKORDER_STUCK_IN_PROGRESS",
      title: "Work Order Schedule Has Lapsed And Stuck In Progress",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
  };

  return FormsData[shortname] || shortname;
};

export const getSMSDataForAlert = (longName) => {
  // ----------------------------------------------------------------------------
  //   WELCOME_MESSAGE: "sms_wm",
  //   SUGGEST_SCHEDULE: "sms_ss",
  //   SCHEDULE_SUGGESTED: "sms_sstd",
  //   CLAIMABLE_WORKORDER: "sms_cw",
  //   SCHEDULE_APPROVED: "sms_sa",
  //   WORKORDER_STOPPED: "sms_ws",
  //   ESTIMATION_SUBMITTED: "sms_es",
  //   ESTIMATION_APPROVED: "sms_ea",
  //   ESTIMATION_DECLINED: "sms_ed",
  //   REQUEST_FOR_REVISION_RAISED: "sms_rfr_r",
  //   WORKORDER_REJECTED: "sms_wr",
  //   WORKORDER_COMPLETED: "sms_wc",
  //   PAYMENT_TRANSFERED: "sms_pt",
  //   NEW_MESSAGE_IN_WORKORDER: "sms_nmw",
  //   REQUESTED_FOR_RESCHEDULE: "sms_rr",
  //   RATINGS_REVIEW_REQUESTED: "sms_rrr",
  //   SEND_FEEDBACK: "sms_sf",
  //   RATINGS_REVIEW_SUBMITTED: "sms_rr_s",
  //   RESCHEDULE_FEEDBACK_REQUESTED: "sms_rfr",
  //   TIME_LAPSED_REASON_REQUESTED: "sms_tlrr",
  //   REQUEST_FOR_APPROVE_SCHEDULE_FROM_SUGGESTED_SCHEDULES: "sms_rfas_s",
  //   DAILY_REMINDER_TO_SERVICE_PROVIDER_OF_UNCLAIMED_THE_WORK_ORDERS: "sms_drsp_uwo",
  //   REMINDER_FOR_RESCHEDULE_THE_WORKORDER: "sms_rfsw",
  //   WORKORDER_MOVED_FROM_SCHEDULED_TO_IN_PROGRESS: "sms_wmst_ip",
  //   APPOINTMENT_REMINDER_24_HOURS_BEFORE: "sms_ar_24hb",
  //   DAILY_REMINDER_OF_IN_PROGRESS_WORKORDERS: "sms_dripw",
  //   OLD_WORKORDER_RESTARTED: "sms_owr",
  //   WORKORDER_RETURN_TRIP: "sms_wrt",
  //   RESCHEDULE_WORKORDER_BASED_ON_SERVICE_PROVIDER_ESTIMATION: "sms_rwbospe",
  //   APPROVAL_AMOUNT_APPROVED: "sms_aaa",
  // ----------------------------------------------------------------------------

  const fomrData = Object.freeze({
    sms_owr: {
      // OLD_WORKORDER_RESTARTED
      title: "Work Order Restarted",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": true,
    },
    sms_wrt: {
      // WORKORDER_RETURN_TRIP
      title: "Work Order Return Trip Info Message",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_rwbospe: {
      // RESCHEDULE_WORKORDER_BASED_ON_SERVICE_PROVIDER_ESTIMATION
      title: "Reschedule work order based on service provider estimation",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_aaa: {
      // APPROVAL_AMOUNT_APPROVED
      title: "Approval Amount Approved",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": false,
    },
    sms_wm: {
      // "WELCOME_MESSAGE",
      title: "Welcome SMS for Work Order",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_sstd: {
      // "SCHEDULE_SUGGESTED",
      title: "Work Order Updated With Schedules",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": true,
    },
    sms_ss: {
      // "SUGGEST_SCHEDULE",
      title: "Suggest a Schedule",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_cw: {
      //  "CLAIMABLE_WORKORDER",
      title: "Claim Work Order",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_sa: {
      //  "SCHEDULE_APPROVED",
      title: "Work Order Schedule Approved",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": false,
    },
    sms_ws: {
      //  "WORKORDER_STOPPED",
      title:
        "Work Order Stopped Work Order Stopped Due To Property Manager/Tenant/Service Provider Unresponsive",
      "title_for_Property Manager":
        "Work Order Stopped Due To Service Provider Unresponsive",
      "title_for_Service Provider":
        "Work Order Stopped Due To Property Manager Unresponsive",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": true,
    },
    sms_es: {
      //  "ESTIMATION_SUBMITTED",
      title: "Estimate Submitted/Revised and Resubmitted By Service Provider",
      title_for_Admin:
        "Estimate Submitted/Revised and Resubmitted By Service Provider",
      "title_for_Super Admin":
        "Estimate Submitted/Revised and Resubmitted By Service Provider",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": false,
    },
    sms_ea: {
      // "ESTIMATION_APPROVED",
      title: "Estimate Approved By Property Manager",
      "title_for_Property Manager": "Estimate Approved By Admin",
      "title_for_Service Provider":
        "Estimate Approved By Property Manager/Admin",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": true,
    },
    sms_ed: {
      // "ESTIMATION_DECLINED",
      title: "Estimate Rejected By Property Manager",
      "title_for_Property Manager": "Estimate Rejected By Admin",
      "title_for_Service Provider":
        "Estimate Rejected By Property Manager/Admin",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": true,
    },
    sms_rfr_r: {
      //  "REQUEST_FOR_REVISION_RAISED",
      title: "Request for Revision",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_wr: {
      // " "WORKORDER_REJECTED",",
      title: "Work Order Is Rejected",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": true,
    },
    sms_wc: {
      // "WORKORDER_COMPLETED",
      title: "Completed By Service Provider",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": false,
    },
    sms_pt: {
      // "PAYMENT_TRANSFERED",
      title: "Payment Transfered To Service Provider",
      "title_for_Service Provider": "Payment Transfered",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_nmw: {
      // "NEW_MESSAGE_IN_WORKORDER",
      title: "Work Order New Message",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": true,
    },
    sms_rr: {
      // "REQUESTED_FOR_RESCHEDULE",
      title: "Request For Reschedule",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_rrr: {
      // "RATINGS_REVIEW_REQUESTED",
      title: "Rating Review Request",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_sf: {
      //  "SEND_FEEDBACK",
      title: "Receive Feedback",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_rr_s: {
      // "RATINGS_REVIEW_SUBMITTED",
      title: "Rating Submitted",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_rfr: {
      // "RESCHEDULE_FEEDBACK_REQUESTED",
      title: "Reschedule feedback",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_tlrr: {
      // "TIME_LAPSED_REASON_REQUESTED",
      title: "Time Lapsed Reason",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_rfas_s: {
      //  "REQUEST_FOR_APPROVE_SCHEDULE_FROM_SUGGESTED_SCHEDULES",
      title: "Approve Schedule From Suggested",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_drsp_uwo: {
      // "DAILY_REMINDER_TO_SERVICE_PROVIDER_OF_UNCLAIMED_THE_WORK_ORDERS",
      title: "Unclaimed Work Orders",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_rfsw: {
      //  "REMINDER_FOR_RESCHEDULE_THE_WORKORDER",
      title: "Reschedule Work Order Reminder",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    sms_wmst_ip: {
      //  "WORKORDER_MOVED_FROM_SCHEDULED_TO_IN_PROGRESS",
      title: "Work Order Moved To In Progress Status",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": true,
    },
    sms_ar_24hb: {
      // "APPOINTMENT_REMINDER_24_HOURS_BEFORE",
      title: "Appointment Reminder (Before 24h)",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_dripw: {
      //  "DAILY_REMINDER_OF_IN_PROGRESS_WORKORDER",
      title: "Work Order In-Progress Reminders",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    sms_aa_d: {
      //  "APPROVAL_AMOUNT_DECLINED",
      title: "Approval Amount Declined By Admin",
      "Super Admin": false,
      Admin: false,
      "Property Manager": true,
      "Service Provider": false,
    },
  });
  return fomrData[longName] || longName;
};
export const getNotificationDataForAlert = (longName) => {
  // ----------------------------------------------------------------
  //   NEW_VENDOR_REGISTRATION_ADMIN_ALERT: "ntfc_nvr_aa",
  //   SUGGEST_SCHEDULE: "ntfc_ss",
  //   ESTIMATION_REQUEST_ADMIN: "ntfc_er_a",
  //   SCHEDULE_SUGGESTED: "ntfc_sstd",
  //   SCHEDULE_APPROVED: "ntfc_sa",
  //   CLAIMABLE_WORKORDER: "ntfc_cw",
  //   DISPATCH_SERVICE_PROVIDERS_ALERT: "ntfc_dspa",
  //   WORKORDER_STOPPED: "ntfc_ws",
  //   ESTIMATION_SUBMITTED: "ntfc_es",
  //   ESTIMATION_APPROVED: "ntfc_ea",
  //   ESTIMATION_DECLINED: "ntfc_ed",
  //   REQUEST_FOR_REVISION_RAISED: "ntfc_rfr_r",
  //   WORKORDER_REJECTED: "ntfc_wr",
  //   WORKORDER_COMPLETED: "ntfc_wc",
  //   INVOICE_SUBMITTED: "ntfc_is",
  //   INVOICE_UPDATED: "ntfc_iu",
  //   PAYMENT_RECEIVED: "ntfc_pr",
  //   INVOICE_DISPUTED_TO_INVOICED: "ntfc_id_ti",
  //   NEW_MESSAGE_IN_WORKORDER: "ntfc_nmw",
  //   REQUESTED_FOR_RESCHEDULE: "ntfc_rr",
  //   NEW_UNDER_REVIEW_WORKORDER: "ntfc_nurw",
  //   TASK_CREATED: "ntfc_tc",
  //   TASK_UPDATED: "ntfc_tu",
  //   TASK_COMPLETED: "ntfc_tc",
  //   TASK_DELETED: "ntfc_td",
  //   TIME_LAPSED_REASON_RECEIVED: "ntfc_tlrr",
  //   RESCHEDULE_FEEDBACK_RECEIVED: "ntfc_rfr",
  //   APPROVAL_AMOUNT_APPROVED: "ntfc_aaa",
  //   APPROVAL_AMOUNT_DECLINED: "ntfc_aa_d",
  //   ADMIN_ALERT_NON_COMMUNICATION_FROM_TENANT: "ntfc_aa_ncft",
  //   WORKORDER_STUCK_IN_PROGRESS: "ntfc_wsip",
  //   WORKORDER_PENDING_TO_DISPATCH: "ntfc_wpd",
  //   ADMIN_NOTIFY_OF_UNCLAIMED_WORKORDERS_BY_SERVICE_PROVIDER: "ntfc_anuw_sp",
  //   WORKORDER_MOVED_FROM_SCHEDULED_TO_IN_PROGRESS: "ntfc_wmst_ip",
  //   OLD_WORKORDER_RESTARTED: "ntfc_owr",
  // ----------------------------------------------------------------

  const formData = Object.freeze({
    ntfc_wmst_ip: {
      //   WORKORDER_MOVED_FROM_SCHEDULED_TO_IN_PROGRESS:
      title: "Work Order Moved To In Progress Status",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_owr: {
      // OLD_WORKORDER_RESTARTED
      title: "Work Order Restarted",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },

    ntfc_nvr_aa: {
      //  "NEW_VENDOR_REGISTRATION_ADMIN_ALERT",
      title: "New Vendor Registration",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_sstd: {
      // "SCHEDULE_SUGGESTED",
      title: "Work Order Updated With Schedules",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_ss: {
      // "SUGGEST_SCHEDULE",
      title: "Suggest a Schedule",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_er_a: {
      //  "ESTIMATION_REQUEST_ADMIN",
      title: "Admin Estimation",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_rfr_r: {
      //  "REQUEST_FOR_REVISION_RAISED",
      title: "Request for Revision",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    ntfc_sa: {
      // "SCHEDULE_APPROVED",
      title: "Work Order Schedule Approved",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },
    ntfc_cw: {
      title: "Claim Work Order", //  "CLAIMABLE_WORKORDER",
      "Super Admin": false,
      Admin: false,
      "Property Manager": false,
      "Service Provider": true,
    },
    ntfc_dspa: {
      title: "Dispatch Service Provider Alert", /// "DISPATCH_SERVICE_PROVIDERS_ALERT",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_ws: {
      // "WORKORDER_STOPPED",
      title:
        "Work Order Stopped Work Order Stopped Due To Property Manager/Tenant/Service Provider Unresponsive",
      "title_for_Property Manager":
        "Work Order Stopped Due To Service Provider Unresponsive",
      "title_for_Service Provider":
        "Work Order Stopped Due To Property Manager Unresponsive",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    ntfc_es: {
      // "ESTIMATION_SUBMITTED",
      title: "Estimate Submitted/Revised and Resubmitted By Service Provider", ////////////////////////  PENDING TITLE
      title_for_Admin:
        "Estimate Submitted/Revised and Resubmitted By Service Provider",
      "title_for_Super Admin":
        "Estimate Submitted/Revised and Resubmitted By Service Provider",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },
    ntfc_ea: {
      // "ESTIMATION_APPROVED",
      title: "Estimate Approved By Property Manager",
      "title_for_Property Manager": "Estimate Approved By Admin",
      "title_for_Service Provider":
        "Estimate Approved By Property Manager/Admin",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": true, /////// PENDING CHECK API
    },
    ntfc_ed: {
      // "ESTIMATION_DECLINED",
      title: "Estimate Declined By Property Manager",
      "title_for_Property Manager": "Estimate Declined By Admin",
      "title_for_Service Provider":
        "Estimate Declined By Property Manager/Admin",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },

    ntfc_wr: {
      // "WORKORDER_REJECTED",
      title: "Rejected Work Order",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_wc: {
      // "WORKORDER_COMPLETED",
      title: "Completed By Service Provider",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_is: {
      // "INVOICE_SUBMITTED",
      title: "Invoice Raised By Service Provider",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_pr: {
      // "PAYMENT_RECEIVED",
      title: "Received Payment From Property Manager",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_id_ti: {
      // "INVOICE_DISPUTED_TO_INVOICED",
      title: "Dispute To Invoice",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_nmw: {
      //  "NEW_MESSAGE_IN_WORKORDER",
      title: "Work Order New Message",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": true,
    },
    ntfc_rr: {
      // "REQUESTED_FOR_RESCHEDULE",
      title: "Request For Reschedule",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_nurw: {
      // ""NEW_UNDER_REVIEW_WORKORDER",",
      title: "Work Order In Under Review",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_tc: {
      // "TASK_COMPLETED",
      title: "Task Completed",
      "Super Admin": true,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_tu: {
      // "TASK_UPDATED",
      title: "Task Updated",
      "Super Admin": true,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_td: {
      // "TASK_DELETED",
      title: "Task Deleted",
      "Super Admin": true,
      Admin: false,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_tlrr: {
      // "TIME_LAPSED_REASON_RECEIVED",
      title: "Time Lapsed Reason",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_rfr: {
      //  "RESCHEDULE_FEEDBACK_RECEIVED",
      title: "Give a reschedule work order feedback",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_aaa: {
      //  "APPROVAL_AMOUNT_APPROVED",
      title: "Approval Amount Approved",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": true,
    },
    ntfc_aa_d: {
      //  "APPROVAL_AMOUNT_DECLINED",
      title: "Approval Amount Declined By Admin",
      "Super Admin": true,
      Admin: true,
      "Property Manager": true,
      "Service Provider": false,
    },
    ntfc_aa_ncft: {
      //  "ADMIN_ALERT_NON_COMMUNICATION_FROM_TENANT",
      title: "Non Communication From Tenant",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_wsip: {
      //  "WORKORDER_STUCK_IN_PROGRESS",
      title: "Work Order Schedule Has Lapsed And Stuck In Progress",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_wpd: {
      //  "WORKORDER_PENDING_TO_DISPATCH",
      title: "Work Order Is Pending To Dispatch",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_anuw_sp: {
      // "ADMIN_NOTIFY_OF_UNCLAIMED_WORKORDERS_BY_SERVICE_PROVIDER",
      title: "Work Order Not Accepted By Vendors",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
    ntfc_iu: {
      //  "INVOICE_UPDATED",
      title: "Invoice Updated By Service Provider",
      "Super Admin": true,
      Admin: true,
      "Property Manager": false,
      "Service Provider": false,
    },
  });
  return formData[longName] || longName;
};

export const COLOR_BUTTON_CARD = {
  redBtn: {
    FG: "#ff4032",
    BG: "#ffada7",
  },
  greenBtn: {
    FG: "#2f8054",
    BG: "#a2e1bf",
  },
  yellowBtn: {
    FG: "#fbc02d",
    BG: "#fef0cd",
  },
  pinkBtn: {
    FG: "#e84555",
    BG: "#ffa3cf",
  },
  blueBtn: {
    FG: "#0169bc",
    BG: "#cdf4ff",
  },
};
