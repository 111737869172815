import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../fetchBaseQuery";

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    notificationList: builder.query({
      query: ({ offset, limit, order_by, sort_by }) =>
        `/notifications?offset=${offset}&limit=${limit}&order_by=${order_by}&sort_by=${sort_by}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    unreadNotifications: builder.query({
      query: () => "notifications/unread-notifications",
      transformResponse: (response, meta, arg) => response.data,
    }),

    markAsReadNotification: builder.mutation({
      query: ({ id }) => ({
        url: `/notifications/mark-as-read`,
        method: "POST",
        ...(id ? { body: { notification_id: id } } : {}),
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    deleteNotification: builder.mutation({
      query: (id) => ({
        url: `/notifications/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useNotificationListQuery,
  useUnreadNotificationsQuery,
  useMarkAsReadNotificationMutation,
  useDeleteNotificationMutation,
} = notificationsApi;
