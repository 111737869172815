import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../fetchBaseQuery";

export const serviceTypesApi = createApi({
  reducerPath: "serviceTypesApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getServiceTypes: builder.query({
      query: () => `/service-types`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    createServiceType: builder.mutation({
      query: (data) => ({
        url: `/service-types`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    updateServiceType: builder.mutation({
      query: ({ id, data }) => ({
        url: `/service-types/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    deleteServiceType: builder.mutation({
      query: ({ id }) => ({
        url: `/service-types/${id}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetServiceTypesQuery,
  useCreateServiceTypeMutation,
  useUpdateServiceTypeMutation,
  useDeleteServiceTypeMutation,
} = serviceTypesApi;
