import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const disputesApi = createApi({
  reducerPath: "disputesApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getDisputes: builder.query({
      query: (id) => `/disputes/${id}`,
      transformResponse: (response) => response.data,
    }),

    getDisputesList: builder.query({
      query: ({ offset, limit, order_by, sort_by }) =>
        `/disputes?offset=${offset}&limit=${limit}&order_by=${order_by}&sort_by=${sort_by}`,
      transformResponse: (response) => response.data,
    }),

    createDisputes: builder.mutation({
      query: ({ data, id }) => ({
        url: `/disputes/${id}`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response) => response.data,
    }),

    updateDisputes: builder.mutation({
      query: ({ data, id }) => ({
        url: `/disputes/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response) => response.data,
    }),

    markToInvoiceDisputes: builder.mutation({
      query: ({ data, id }) => ({
        url: `/disputes/mark-to-invoice/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response) => response.data,
    }),

    createDisputesComment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/disputes/comment/${id}`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetDisputesQuery,
  useCreateDisputesMutation,
  useCreateDisputesCommentMutation,
  useUpdateDisputesMutation,
  useMarkToInvoiceDisputesMutation,
  useGetDisputesListQuery,
} = disputesApi;
