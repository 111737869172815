import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const tasksApi = createApi({
  reducerPath: "tasksApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getTasksList: builder.query({
      query: ({ type, filteres, offset, limit, order_by, sort_by }) =>
        `/tasks?${type ? `type=${type}` : ""}${
          offset ? `offset=${offset}` : ""
        }${limit ? `&limit=${limit}` : ""}${
          order_by ? `&order_by=${order_by}` : ""
        }${sort_by ? `&sort_by=${sort_by}` : ""}${filteres ? filteres : ""}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    getTask: builder.query({
      query: (id) => `/tasks/${id}`,
      transformResponse: (response, meta, arg) => response.data,
    }),

    createTask: builder.mutation({
      query: ({ id, data }) => ({
        url: `/tasks/${id}`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),

    updateTask: builder.mutation({
      query: ({ id, data }) => ({
        url: `/tasks/${id}`,
        method: "PUT",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
    deleteTask: builder.mutation({
      query: ({ id, data }) => ({
        url: `/tasks/${id}`,
        method: "DELETE",
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetTaskQuery,
  useGetTasksListQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
} = tasksApi;
