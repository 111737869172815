

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const woHistoryApi = createApi({
  reducerPath: "woHistoryApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getWoStatusHistory: builder.query({
      query: (id) => `/status-history/${id}`,
      transformResponse: (response, meta, arg) => response,
    }),
  }),
});

export const {
  useGetWoStatusHistoryQuery,
} = woHistoryApi;
