import { lazy, useEffect, useState } from "react";
import "./App.css";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getAuthToken } from "./utils/index";
import { PUBLIC_ROUTES, USER_TYPES } from "./constants/index";
import "moment-timezone";
import { registerSocketListeners } from "./services/Socket.Io";
import moment from "moment";
import _ from "lodash";

const SignIn = lazy(() => import("./containers/SignIn"));
const ErrorPage = lazy(() => import("./containers/ErrorPage"));
const Register = lazy(() => import("./containers/Register"));
const SignUp = lazy(() => import("./containers/SignUp"));
const ForgotPassword = lazy(() => import("./containers/ForgotPassword"));
const TermsOfUse = lazy(() => import("./containers/TermsOfUse"));
const Layout = lazy(() => import("./containers/Layout"));
const Vendors = lazy(() => import("./components/vendors/templates"));
const VendorsProfile = lazy(() =>
  import("./components/vendors/templates/profile")
);
const PropertyManagers = lazy(() =>
  import("./components/property-manager/templates")
);
const Admin = lazy(() => import("./components/admin"));
const Settings = lazy(() => import("./components/settings/templates"));
const ResetPassword = lazy(() => import("./containers/ResetPassword"));
const Alerts = lazy(() => import("./components/alerts/templates"));
const ServiceArea = lazy(() => import("./components/admin/service-area"));
const ServiceTypes = lazy(() => import("./components/admin/service-type"));
const UsersList = lazy(() => import("./components/admin/users"));
const Emails = lazy(() => import("./components/admin/emails/templates"));
const NotificationsList = lazy(() =>
  import("./components/admin/notifications")
);
const WorkOrdersCreate = lazy(() =>
  import("./components/work-orders/create/template")
);
const WorkOrderOverview = lazy(() =>
  import("./components/work-orders/overview/template")
);
const WorkOrderDashBoard = lazy(() =>
  import("./components/work-orders/dashboard/template")
);
const StatusWiseWorkOrder = lazy(() =>
  import("./components/work-orders/status-wise/template")
);
const WorkOrderCalendar = lazy(() =>
  import("./components/work-orders/calander/template")
);
const CreateInvoice = lazy(() =>
  import("./components/invoice/create/template")
);
const InvoicesDashboard = lazy(() =>
  import("./components/invoice/dashboard/template")
);
const DisputePage = lazy(() =>
  import("./components/dispute/main-page/template")
);
const DisputeOverView = lazy(() =>
  import("./components/dispute/over-view/template")
);
const HomePage = lazy(() => import("./components/home-page/template"));
const Properties = lazy(() =>
  import("./components/property/main-page/template")
);
const WorkOrderSuggestScheduleTenant = lazy(() =>
  import("./components/work-orders/tenant-suggest-schedule")
);
const FeedBackTenantWorkOrder = lazy(() =>
  import("./components/feedback/tenant")
);
const TaskPage = lazy(() => import("./components/tasks"));
const ProjectDashboard = lazy(() => import("./components/projects/dashboard"));
const WorkOrderPriority = lazy(() =>
  import("./components/work-orders/set-priority")
);
const WorkOrderTimeLapseFeedback = lazy(() =>
  import("./components/feedback/time-lapse")
);
const WorkOrderResheduleFeedback = lazy(() =>
  import("./components/feedback/reshedule-feedback")
);
const StripeRefreshAccountLink = lazy(() =>
  import("./components/stripe/refresh-account-link")
);
const TenantCommunication  = lazy(() =>import ("./components/tenant-communication"))


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = getAuthToken();
  const urlToken = searchParams.get("token");

  moment.tz.setDefault("America/Los_Angeles");

  const userRole = "Admin"; // Determine the user's role
  let routes = [
    {
      title: "Login to Candu Maintenance",
      path: "/",
      key: "home",
      element: <SignIn />,
      roles: [],
    },
    {
      title: "Choose a User Type",
      path: "/register",
      key: "register",
      element: <Register />,
      exact: true,
      roles: [],
    },
    {
      title: "Registration | Candu Maintenance",
      path: "/signup",
      key: "signup",
      element: <SignUp />,
      exact: true,
      roles: [],
    },
    {
      title: "Forgot Password",
      path: "/forgot-password",
      key: "forgotPassword",
      element: <ForgotPassword />,
      roles: [],
      exact: true,
    },
    {
      title: "Reset Password",
      path: "/reset-password",
      key: "resetPassword",
      element: <ResetPassword />,
      roles: [],
      exact: true,
    },
    {
      title: "Terms of Use | Candu Maintenance",
      path: "/terms",
      key: "TermsOfUse",
      element: <TermsOfUse />,
      exact: true,
      roles: [],
    },
    {
      title: "Dashboard",
      path: "/dashboard",
      key: "Dashboard",
      element: <HomePage />,
      exact: true,
      roles: [],
    },
    {
      title: "Vendors",
      path: "/service-providers",
      key: "Vendors",
      element: <Vendors />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Vendors Profile",
      path: "/service-providers/profile/:vendor_id",
      key: "VendorsProfile",
      exactPath: "/service-providers/profile",
      element: <VendorsProfile />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Property Managers",
      path: "/property-managers",
      key: "propertyManagers",
      element: <PropertyManagers />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Projects",
      path: "/projects",
      key: "Projects",
      element: <ProjectDashboard />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Properties",
      path: "/properties",
      key: "properties",
      element: <Properties />,
      exact: true,
      roles: [],
    },
    {
      title: "Invoices",
      path: "/invoices/:tab_value",
      exactPath: "/invoices",
      key: "invoices",
      element: <InvoicesDashboard />,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Settings",
      path: "/settings",
      key: "settings",
      element: <Settings />,
      roles: [],
    },
    {
      title: "Disputes",
      path: "/disputes/:tab_value",
      exactPath: "/disputes",
      key: "disputes",
      element: <DisputePage />,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Disputes/ Overview",
      path: "/disputes/dispute-overview/:dispute_id",
      exactPath: "/disputes/dispute-overview",
      key: "disputesOverview",
      element: <DisputeOverView />,
      roles: [],
      exact: true,
    },
    {
      title: "Alerts",
      path: "/alerts",
      key: "alerts",
      element: <Alerts />,
      roles: [],
    },
    {
      title: "Notifications",
      path: "/notifications",
      key: "notifications",
      element: <NotificationsList />,
      exact: true,
      roles: [],
    },

    // "Work Orders"
    {
      title: "Work Orders",
      path: "/work-orders",
      key: "WorkOrders",
      element: <WorkOrderDashBoard />,
      roles: [],
      exact: true,
    },
    {
      title: "Work Orders / Create",
      path: "/work-orders/create",
      key: "WorkOrdersCreate",
      element: <WorkOrdersCreate />,
      roles: [],
      exact: true,
    },

    {
      title: "Work Orders / Overview",
      path: "/work-order/overview/:work_order_id",
      key: "workOrderOverview",
      exactPath: "/work-order/overview",
      element: <WorkOrderOverview />,
      roles: [],
      exact: true,
    },

    {
      title: "workorder Suggest Schedule",
      path: "/workorder-suggest-schedule/:work_order_id",
      exactPath: "/work-order/Schedule",
      key: "workorderSuggestSchedule",
      exact: true,
      element: <WorkOrderSuggestScheduleTenant />,
      roles: [],
    },

    {
      title: "workorder Reshcedule Feedback",
      path: "/workorder-reschedule-feedback/:work_order_id",
      exactPath: "/work-order/reschedule-feedback",
      key: "workorderRescheduleFeedback",
      exact: true,
      element: <WorkOrderResheduleFeedback />,
      roles: [],
    },
    {
      title: "workorder Time Lapse Feedback",
      path: "/workorder-time-lapsed-feedback/:work_order_id",
      exactPath: "/work-order/time-lapsed-feedback",
      key: "workorderTimeLapseFeedback",
      exact: true,
      element: <WorkOrderTimeLapseFeedback />,
      roles: [],
    },
    {
      title: "Work Orders / Status",
      path: "/work-orders/:status/:project_id?",
      exactPath: "/work-order/status",
      key: "workOrderStatus",
      element: <StatusWiseWorkOrder />,
      roles: [],
      exact: true,
    },
    {
      title: "Work Orders / Priority",
      path: "/work-orders/priority/:project_id",
      key: "workOrdersPriority",
      exactPath: "/work-order/priority",
      element: <WorkOrderPriority />,
      roles: [],
      exact: true,
    },

    {
      title: "Work Orders / calendar",
      path: "/work-orders/calendar",
      key: "workOrderCalendar",
      element: <WorkOrderCalendar />,
      roles: [],
      exact: true,
    },
    {
      title: "Feedback",
      path: "/workorder-ratings-review/:work_order_id",
      key: "feedbackTenant",
      element: <FeedBackTenantWorkOrder />,
      roles: [],
      exact: true,
    },
    {
      title: "Create Invoice",
      path: "/create-invoice/:work_order_id",
      key: "createInvoice",
      exactPath: "/create-invoice",
      element: <CreateInvoice />,
      roles: [],
      exact: true,
    },
    {
      title: "Tasks",
      path: "/tasks",
      key: "tasks",
      element: <TaskPage />,
      roles: [],
      exact: true,
    },

    {
      title: "Stripe Candu Maintenance",
      path: "/stripe-refresh-account-link",
      key: "stripeRefreshAccountLink",
      element: <StripeRefreshAccountLink />,
      roles: [],
      exact: true,
    },
    {
      title: "Work Order Communication",
      path: "/workorder-communications/:work_order_id",
      key: "workOrderCommunication",
      exactPath: "/workorder-communications",
      element: <TenantCommunication />,
      roles: [],
      exact: true,
    },
    {
      title: "Admin Dashboard",
      path: "/admin",
      key: "admin",
      element: <Admin />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Admin / Emails",
      path: "/admin/emails",
      key: "admin",
      element: <Emails />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Admin / Users Lists",
      path: "/admin/users",
      key: "admin",
      element: <UsersList />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Admin / Notifications",
      path: "/admin/notifications",
      key: "admin",
      element: <NotificationsList />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Admin / Service Area",
      path: "/admin/service-areas",
      key: "admin",
      element: <ServiceArea />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Admin / Service Type",
      path: "/admin/service-types",
      key: "admin",
      element: <ServiceTypes />,
      exact: true,
      roles: [USER_TYPES.ADMIN],
    },
    {
      title: "Error",
      path: "*",
      key: "error",
      element: <ErrorPage />,
      roles: [],
    },
  ];
  useEffect(() => {
    if (
      !PUBLIC_ROUTES.includes(
        location.pathname.slice(0, location.pathname.lastIndexOf("/")) || "/"
      ) &&
      !token
    ) {
      navigate("/");
    }

    routes.forEach((item) => {
      if (item.path === location.pathname && !item.exactPath) {
        document.title = item.title;
      } else if (location.pathname.includes(item.exactPath)) {
        document.title = item.title;
      }
 
    });
  }, [location.pathname, userRole, token, navigate]);

  const [, setConnectionData] = useState(null);
  const [, setErrorData] = useState(null);

  useEffect(() => {
    registerSocketListeners({
      connection: setConnectionData,
      connect_error: setErrorData,
    });
  }, []);

  return (
    <div className="App">
      <Layout>
        <Routes role={userRole}>
          {routes.map((route, key) => {
            return (
              <Route
                exactPath={route?.exactPath ? route?.exactPath : route.path}
                path={route.path}
                {...route}
                element={
                  route.roles?.length ? (
                    route.roles.includes(userRole) ? (
                      route.element
                    ) : (
                      <ErrorPage />
                    )
                  ) : (
                    route.element
                  )
                }
                key={key}
              />
            );
          })}
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
