import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../fetchBaseQuery";

export const communicationApi = createApi({
  reducerPath: "communicationApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCommunication: builder.query({
      query: ({ id, token }) => ({
        url: `/communications/${id}`,
        method: "GET",
        ...(token
          ? {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          : {}),
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),

    sendCommunication: builder.mutation({
      query: ({ id, data, token }) => ({
        url: `/communications/${id}`,
        method: "POST",
        ...(token
          ? {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          : {}),
        body: data,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const { useGetCommunicationQuery, useSendCommunicationMutation } =
  communicationApi;
