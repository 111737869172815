import moment from "moment";
import {
  COST_STATUS,
  JOB_STATUS,
  TASK_TYPE_OBJECT,
  USER_TYPES,
} from "../constants";
import _ from "lodash";
import { useEffect, useState } from "react";
import { registerSocketListeners } from "../services/Socket.Io";

export const setAuthToken = (token = "") => {
  localStorage.setItem("authToken", token);
};

export const getAuthToken = () => localStorage.getItem("authToken") || null;

/**
 * This function return FullCalendar provided attribute value based on work order configs
 * @param {String} attributeName
 * @param {Object} job
 * @returns attribute value
 */
export const getFullCalendarAttrValue = (attributeName = "", job = {}) => {
  if (attributeName === "validRange") {
    if (
      [JOB_STATUS.COMPLETED, JOB_STATUS.INVOICED, JOB_STATUS.STOPPED].includes(
        job?.status
      )
    )
      return {};
    if (job?.priority === "High")
      return {
        start: new Date(),
        end: new Date(
          new Date(job?.created_at).getTime() + 24 * 2 * 60 * 60 * 1000 // 2 days
        ),
      };
    return {
      start: new Date(new Date().getTime() + 24 * 3 * 60 * 60 * 1000),
    };
  } else if (attributeName === "selectAllow") {
    if (job?.priority === "High")
      return (data) =>
        !moment(data?.start).isBefore(moment()) &&
        moment(data?.end).isBefore(moment(job?.created_at).add(48, "hours")) &&
        !data?.allDay;
    return (data) => !moment(data?.start).isBefore(moment()) && !data?.allDay;
  }
};

export const getAmountBasedOnEstimateSourceAndMarkUp = (
  userData,
  estimation_source,
  withMarkupAmount,
  withoutMarkupAmount
) => {
  const { userType, is_admin } = userData;

  let test_amount = 0;
  if (estimation_source === "Admin") {
    if (is_admin) {
      test_amount = withoutMarkupAmount;
    } else if (userType === USER_TYPES.PROPERTY_MANAGER) {
      test_amount = withoutMarkupAmount;
    } else if (userType === USER_TYPES.SERVICE_PROVIDER) {
      test_amount = withMarkupAmount;
    } else {
      test_amount = withMarkupAmount;
    }
  } else if (estimation_source === "Service Provider") {
    if (is_admin) {
      test_amount = withMarkupAmount;
    } else if (userType === USER_TYPES.PROPERTY_MANAGER) {
      test_amount = withMarkupAmount;
    } else if (userType === USER_TYPES.SERVICE_PROVIDER) {
      test_amount = withoutMarkupAmount;
    } else {
      test_amount = withoutMarkupAmount;
    }
  }

  return test_amount;
};
/**
 *  FOR ADD TOTAL AMOUNT IN INTIAL RESPONSE OF WORK ORDER
 * @param {Array} descriptions
 * @param {String} estimation_source
 * @param {Object} userData
 * @returns Total amount with their job role and based on Estimation Source
 */
export const FilterInititalTotalCost = (
  descriptions,
  estimation_source,
  userData
) => {
  const userType = userData.role?.name;
  const is_admin = ["Admin", "Super Admin"].includes(userData?.role?.name);
  const withMarkupAmount = _.reduce(
    descriptions,
    (acc, cur) => acc + Number(cur.amount) + Number(cur.markup_amount),
    0
  );
  const withoutMarkupAmount = _.reduce(
    descriptions,
    (acc, cur) => acc + Number(cur.amount),
    0
  );
  return getAmountBasedOnEstimateSourceAndMarkUp(
    { userType: userType, is_admin: is_admin },
    estimation_source,
    withMarkupAmount,
    withoutMarkupAmount
  );
};
export const FilterEstimatedCost = (rowData, wo, userData, flag = "") => {
  const estimations =
    (rowData?.estimations && rowData?.estimations[0]) || rowData;

  const withoutMarkupAmount = Number(estimations?.amount);
  const withMarkupAmount =
    Number(estimations?.amount) + Number(estimations?.markup_amount);

  let test_amount = getAmountBasedOnEstimateSourceAndMarkUp(
    userData,
    wo.estimation_source,
    withMarkupAmount,
    withoutMarkupAmount
  );

  return Number(test_amount);
};

export const getMarkup = (wo, budget) => {
  if (!wo?.service_type) return 0;

  let value = wo.service_type.value;
  if (wo.service_type.type !== "percent") {
    if (budget < value) {
      return 0;
    }
    return value;
  }
  return budget * (value / 100);
};

export const getBudget = (wo, userData) => {
  if (!wo?.budget) {
    return 0.0;
  }
  let budget = Number(wo.budget) || 0;
  if (userData.is_sp) {
    let markUp = getMarkup(wo, budget);
    budget = budget - markUp;
  }
  return budget?.toFixed(2);
};

export const isAllEstimateApprovedInFirstTime = (estimations = []) => {
  const is_aprroved = _.every(estimations, ["status", COST_STATUS.APPROVED]);
  return is_aprroved;
};

export const getTaskTypeName = (task_type) => {
  return _.filter(TASK_TYPE_OBJECT, ["value", task_type])[0]?.name;
};

/**
 *  FOR MAKE COMMON COMPONENT OF REFECTH DATA USING SOCKET
 * @param {String} module
 * @param {Function} refetch
 */
export const RefetchDataUsingSocketIo = (module, refetch = () => {}) => {
  const [moduleRefetch, setModuleRefetch] = useState(false);
  useEffect(() => {
    registerSocketListeners({
      refetch: setModuleRefetch,
    });
    if (moduleRefetch[module]) {
      refetch();
      setModuleRefetch(false);
    }
  }, [module, moduleRefetch, refetch]);
};

/**
 * @param {Number} number
 * @returns {String} KFormat
 */
export const convertToKFormat = (number) => {
  if (number < 1000) return number.toString();
  return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
};
