export const MuiButton = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: "capitalize",
      borderRadius: "20px",
      fontSize: "16px",
      fontWeight: 600,
      [theme.breakpoints.down("768")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("350")]: {
        fontSize: "10px",
      },
    }),
  },
};

export const MuiFormLabel = {
  styleOverrides: {
    asterisk: ({ theme }) => ({
      color: theme.palette.error.main,
    }),
    root: ({ theme }) => ({
      fontSize: "15px",
      color: "black",
      marginBottom: 5,
      fontWeight: 500,
      alignSelf: "flex-start",
      [theme.breakpoints.down("768")]: {
        fontSize: "12px",
      },
    }),
  },
};

export const MuiFormHelperText = {
  styleOverrides: {
    root: ({ theme }) => ({
      margin: "3px 0 0",
      color: "#d32f2f",
      height: "22px",
      [theme.breakpoints.down("768")]: {
        fontSize: "10px",
      },
    }),
  },
};

export const MuiFormControl = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&>div": {
        "&:hover": {
          "&>fieldset": {
            borderColor: `${theme.palette.primary.main} !important`,
          },
        },
      },
    }),
  },
};

export const MuiTypography = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({}),
  },
  variants: [
    {
      props: { variant: "title" },
      style: {
        fontSize: "1.5rem !important",
        fontWeight: 700,
      },
    },
    {
      props: { variant: "heading" },
      style: {
        fontSize: "32px !important",
        fontWeight: 700,
      },
    },
  ],
};

export const MuiTextField = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      "&>label": {
        fontSize: "8px !important",
      },
      "& .MuiFormHelperText": {
        color: "red !important",
      },
      "&>fieldset": {
        color: "red !importnant",
      },
      "&>div": {
        "&>input": {
          padding: "10px 14px",
          color: "red !importnant",
        },
        background: theme.palette.background.main,
        // background:"#fff",
        // borderColor: "red !important",
        "&:hover": {
          "&>fieldset": {
            borderColor: `${
              ownerState?.error
                ? "red !important"
                : `${theme.palette.primary.main} !important'`
            }`,
          },
        },
        "&>fieldset": {
          borderColor: `${ownerState?.error ? "red !important" : ""}`,
        },
      },
      "& .Mui-focused": {
        "&>fieldset": {
          borderWidth: "1px !important",
        },
      },
    }),
  },
};

export const MuiAutocomplete = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      "&>div": {
        "& input": {
          padding: "1px !important",
        },
        "& .MuiInputBase-root>div": {
          margin: "0px !important",
          height: "25px !important",
          " & svg": {
            fontSize: "21px !important",
          },
        },
      },
    }),
  },
};

export const MuiLink = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontWeight: 600,
      ":hover": {
        color: theme.palette.primaryMedium.main,
      },
    }),
    // variants: [
    //   {
    //     props: { variant: "styled" },
    //     style: ({ theme, ownerState }) => ({
    //       color: theme.palette.primary.main,
    //       textDecoration: "none",
    //       fontWeight: 600,
    //       ":hover": {
    //         color: theme.palette.primaryMedium.main,
    //       },
    //     }),
    //   },
    // ],
  },
};

export const MuiDialog = {
  MuiDialog: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({}),
      paper: ({ ownerState, theme }) => ({
        width: "800px",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "90%",
        },
        borderRadius: "20px",
        padding: "20px",
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        display: "flex",
        padding: "25px 24px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        alignSelf: "stretch",
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "25px",
        padding: "20px",
      }),
    },
  },
};

export const MuiPagination = {
  MuiPagination: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        "& .MuiPagination-ul": {
          padding: "15px",
          "&>li": {
            display: "flex",
            "> button": {
              [theme.breakpoints.down("600")]: {
                fontSize: "12px",
                padding: "0px",
                margin: "0px",
              },
            },
            "&:first-child": {
              justifyContent: "flex-start",
              flexBasis: "auto",
              flexGrow: 1,
              "& :hover": {
                borderColor: "#d6d3d3",
              },
              "> button::after": {
                marginLeft: "10px",
              },
            },
            "&:last-child": {
              flexGrow: 1,
              justifyContent: "flex-end",
              flexBasis: "auto",
              "& :hover": {
                borderColor: "#d6d3d3",
              },
              "> button::before": {
                marginRight: "10px",
              },
            },
          },
        },
      }),
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: () => ({
        "&:hover": {
          background: "none",
        },
        ">button": {
          background: "#f5f5f5",
        },
      }),
    },
  },
};
